import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { PolicyComponent } from 'app/main/policy/policy.component';

const routes = [
    {
        path     : 'policy',
        component: PolicyComponent
    }
];

@NgModule({
    declarations: [
        PolicyComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        FuseSharedModule
    ]
})
export class PolicyModule
{
}
